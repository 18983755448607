<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <the-nerve-table
    :id="'iiotRolesTable'"
    :columns="columns"
    :empty-state="$t('access.roles.list.emptyState')"
    :params="params"
    :is-action-menu-enabled="false"
    :is-row-clickable="canAccess('UI_ROLE:VIEW')"
    store-module="roles"
    :delete-message="$t('access.roles.list.deletedItem')"
    class="mr-4"
    custom-fetch-action="fetch_roles"
    custom-list-getter="getRoles"
    @row-clicked="(params) => AddEditRoles(params.item)"
    @params-changed="(params) => changedParams(params)"
  >
    <template #additional-actions>
      <v-row>
        <v-col cols="5" lg="3" md="4" sm="4">
          <v-select
            id="iiotRolesUserTypeSelect"
            v-model="roleListFilterOption"
            :items="[
              { name: $t('access.roles.list.filter.all'), value: { type: '' } },
              { name: $t('access.roles.list.filter.local'), value: { type: 'local' } },
              { name: $t('access.roles.list.filter.ldap'), value: { type: 'ldap' } },
            ]"
            :label="$t('access.roles.list.userType')"
            prepend-icon="mdi-filter-outline"
            item-value="value.type"
            item-text="name"
            class="default-search-input"
            attach
            @change="rolesFetch()"
          />
        </v-col>
      </v-row>
      <div>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
              v-if="canAccess('UI_ROLE:CREATE') && !isLoggedLdapUser"
              id="iiotRolesAddNewRoleButton"
              fab
              small
              dark
              color="primary"
              class="mr-6"
              v-on="on"
              @click="AddEditRoles()"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('roles.list.addRole') }}</span>
        </v-tooltip>
      </div>
    </template>
  </the-nerve-table>
</template>

<script>
import { TheNerveTable } from 'nerve-ui-components';
import Logger from '@/utils/logger';

export default {
  components: {
    TheNerveTable,
  },
  props: {
    page: {
      type: Number,
    },
    itemsPerPage: {
      type: Number,
    },
    search: {
      type: String,
    },
    type: {
      type: String,
    },
    sortBy: {
      type: Array,
    },
    sortDesc: {
      type: Array,
    },
  },
  data() {
    return {
      roleListFilterOption: this.type === 'all' ? '' : this.type,
      isLoggedLdapUser: false,
      params: {},
    };
  },
  computed: {
    columns() {
      if (this.canAccess('UI_ROLE:DELETE') && !this.isLoggedLdapUser) {
        return [
          {
            text: this.$t('access.roles.list.name'),
            value: 'name',
            width: '30%',
          },
          {
            text: this.$t('access.roles.list.type'),
            value: 'type',
            sortable: false,
            width: '15%',
          },
          {
            text: this.$t('access.roles.list.numberOfAssignedUsers'),
            value: 'users',
            sortable: false,
            length: true,
            width: '20%',
          },
          {
            text: this.$t('access.roles.list.description'),
            value: 'description',
            sortable: false,
            width: '40%',
          },
          {
            text: this.$t('defaultTable.action'),
            value: '',
            width: '0%',
            sortable: false,
            component: {
              sfc: () => import('@/components/roles/RoleListTableAction.vue'),
              props: {
                iconsAction: ['Delete'],
                fetchAction: () => this.filterBy,
              },
            },
          },
        ];
      }
      return [
        {
          text: this.$t('access.roles.list.name'),
          value: 'name',
        },
        {
          text: this.$t('access.roles.list.type'),
          value: 'type',
          sortable: false,
        },
        {
          text: this.$t('access.roles.list.numberOfAssignedUsers'),
          value: 'users',
          sortable: false,
          length: true,
        },
        {
          text: this.$t('access.roles.list.description'),
          value: 'description',
          sortable: false,
        },
      ];
    },
  },
  created() {
    this.params = {
      page: this.page || 1,
      itemsPerPage: this.itemsPerPage || 10,
      search: this.search || '',
      filterBy: this.type === 'all' ? '' : this.type,
      sortBy: this.sortBy || [],
      sortDesc: this.sortDesc || [],
    };
    if (JSON.parse(localStorage.getItem('userDetails')) && JSON.parse(localStorage.getItem('userDetails')).ldap) {
      this.isLoggedLdapUser = true;
    }
  },
  methods: {
    async filterBy() {
      try {
        await this.$store.dispatch('roles/fetch_roles', this.params);
      } catch (e) {
        Logger.error(e);
      }
    },
    changedParams(params) {
      this.params = params;
      this.$router
        .push({
          name: 'Roles',
          query: {
            page: params.page,
            itemsPerPage: params.itemsPerPage,
            search: params.search || undefined,
            type: params.filterBy === '' ? 'all' : params.filterBy,
            sortBy: params.sortBy ? params.sortBy[0] : [],
            sortDesc: params.sortDesc ? params.sortDesc[0] : [],
          },
        })
        .catch(() => {});
    },
    rolesFetch() {
      try {
        this.params.filterBy = this.roleListFilterOption;
        this.params.page = 1;
        this.$store.dispatch('roles/fetch_roles', this.params);
      } catch (e) {
        Logger.error(e);
      }
    },
    AddEditRoles(item) {
      if (item && item.id) {
        this.$router.push({ name: 'Role Details', params: { id: item.id } });
        return;
      }
      this.$router.push({ name: 'Role Details', params: { id: 'new' } });
    },
  },
};
</script>
